

















































































































































































































































































































































































































import Vue from "vue";
import { mapState } from "vuex";

export default Vue.extend({
  name: "lawrence",
  data: () => ({
    //lawrence: [1, 2, 3]
  })
  //components: {},
  //computed: {},
  //mounted() {}
});
